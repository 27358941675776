import { defineStore } from 'pinia';

export const useNotifyStore = defineStore('notifyStore', {
  state: () => ({
    notify: {
      show: false,
      type: 'error'
    }
  }),
  actions: {
    show(notify) {
      this.notify = { show: true, type: 'error', ...notify };
    },
    hide() {
      this.notify = { ...this.notify, show: false };
    }
  }
});
