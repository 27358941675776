import Vue from 'vue';
import VueRouter from 'vue-router';

import { pinia } from '@/main';
import { useAuthStore } from '@/store/authStore';
import routes from './routes';

Vue.use(VueRouter);

// let userRole;

const checkLoggedIn = async () => {
  const authStore = useAuthStore(pinia);

  if (!authStore.loggedIn) {
    await authStore.tryAutoLogin();
  }
  // userRole = authStore.userRole;
  return authStore.loggedIn;
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const loggedIn = await checkLoggedIn();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!loggedIn) {
      next({
        name: 'login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
