/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import minifyTheme from 'minify-css-string';
import '@mdi/font/css/materialdesignicons.css';
import i18n from '@/i18n';

Vue.use(Vuetify);

// Vue.use(DatetimePicker);

Vue.config.silent = process.env.NODE_ENV === 'production';

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    // options: {
    //   minifyTheme,
    //   themeCache: {
    //     get: key => localStorage.getItem(key),
    //     set: (key, value) => localStorage.setItem(key, value)
    //   }
    // },
    dark: JSON.parse(localStorage.getItem('darkMode')) || false,
    themes: {
      light: {
        primary: '#a3cd3a',
        loginBackgroundColor: '#FFFFFF'
      },
      dark: {
        primary: '#5f5c67',
        loginBackgroundColor: '#000000'
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
});
