import { defineStore } from 'pinia';

import i18n from '@/i18n';
import { apiClient } from '@/utils';
import { useNotifyStore } from './notifyStore';

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    currentUserData: null,
    currentUserDataLoading: false,
    forgetPasswordLoading: false,
    forgetPasswordStatus: {},
    forgetPasswordData: {}
  }),
  getters: {
    loggedIn: state => !!state.currentUserData,
    userRole: state => state.currentUserData?.role
  },
  actions: {
    async tryAutoLogin() {
      const notifyStore = useNotifyStore();
      try {
        notifyStore.hide();
        this.currentUserDataLoading = true;
        const { status, data } = await apiClient.get('/auth/me');
        // if (status === 200 && data?.user && data?.user?.role === 'admin') {
        if (status === 200 && data?.user) {
          this.currentUserData = data.user;
          this.currentUserDataLoading = false;
        } else {
          this.currentUserData = null;
          this.currentUserDataLoading = false;
          const notify = { message: i18n.t('required.onlyAdmins') };
          notifyStore.show(notify);
        }
      } catch (error) {
        this.currentUserData = null;
        this.currentUserDataLoading = false;
        if (error.response !== undefined) {
          const notify = {
            message: error?.response?.data?.message || i18n.t('labels.somethingWrong')
          };
          if (error.response.status !== 401) {
            notifyStore.show(notify);
          }
        }
      }
    },

    async onLogin({ phone, password, locale }) {
      const notifyStore = useNotifyStore();
      try {
        notifyStore.hide();
        this.currentUserDataLoading = true;
        const { status, data } = await apiClient.post('/auth/login', {
          phone,
          password,
          locale
        });
        // if (status === 200 && data?.token && data?.user?.role === 'admin') {
        if (status === 200 && data?.token) {
          this.currentUserData = data.user;
          this.currentUserDataLoading = false;
        } else {
          this.currentUserData = null;
          this.currentUserDataLoading = false;
          const notify = { message: i18n.t('required.onlyAdmins') };
          notifyStore.show(notify);
        }
      } catch (error) {
        this.currentUserDataLoading = false;
        if (error.response !== undefined) {
          const notify = {
            message: error?.response?.data?.message || i18n.t('labels.somethingWrong')
          };
          notifyStore.show(notify);
        }
      }
    },
    async onLogout() {
      const notifyStore = useNotifyStore();
      try {
        notifyStore.hide();
        this.currentUserDataLoading = true;
        await apiClient.get('/auth/logout');
        setTimeout(() => {
          this.currentUserDataLoading = false;
          this.currentUserData = null;
        }, 1000);
      } catch (error) {
        this.currentUserData = null;
        this.currentUserDataLoading = false;
        if (error.response !== undefined) {
          const notify = {
            message: error?.response?.data?.message || i18n.t('labels.somethingWrong')
          };
          notifyStore.show(notify);
        }
      }
    },
    async onForgetPassword(phoneNo) {
      const notifyStore = useNotifyStore();
      try {
        notifyStore.hide();
        this.forgetPasswordLoading = true;
        const res = await apiClient.get('/auth/forgot/' + phoneNo);
        if (res.status === 200) {
          notifyStore.show({ type: 'success', message: res?.data?.message });
          this.forgetPasswordStatus = res.status;
          this.forgetPasswordData = { phoneNo: phoneNo, userId: res.data.userId };
          this.forgetPasswordLoading = false;
        }
      } catch (error) {
        this.forgetPasswordLoading = false;
        this.forgetPasswordStatus = {};
        this.forgetPasswordData = {};
        if (error.response !== undefined) {
          const notify = {
            message: error?.response?.data?.message || i18n.t('labels.somethingWrong')
          };
          notifyStore.show(notify);
        }
      }
    },
    async onResetPassword(model) {
      const notifyStore = useNotifyStore();
      try {
        notifyStore.hide();
        this.forgetPasswordLoading = true;
        this.forgetPasswordStatus = {};
        const res = await apiClient.put(`/auth/reset`, model);
        if (res.status === 200) {
          notifyStore.show({ type: 'success', message: res?.data?.message });
          this.forgetPasswordStatus = res.status;
          this.forgetPasswordLoading = false;
        }
      } catch (error) {
        this.forgetPasswordLoading = false;
        this.forgetPasswordStatus = {};
        if (error.response !== undefined) {
          const notify = {
            message: error?.response?.data?.message || i18n.t('labels.somethingWrong')
          };
          notifyStore.show(notify);
        }
      }
    }
  }
});
