import Vue from 'vue';
// import upperFirst from 'lodash/upperFirst';
// import camelCase from 'lodash/camelCase';
import VueTheMask from 'vue-the-mask';
// import VueCompositionAPI from '@vue/composition-api';
import { createPinia, PiniaVuePlugin, setMapStoreSuffix } from 'pinia';

import i18n from './i18n';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins/meta';

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);
// Vue.use(VueCompositionAPI);
Vue.use(VueTheMask);

export const pinia = createPinia();

setMapStoreSuffix('');

// const requireComponent = require.context('./components', false, /Base[A-Z]\w+\.(vue|js)$/);

// requireComponent.keys().forEach(fileName => {
//   const componentConfig = requireComponent(fileName);

//   const componentName = upperFirst(camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1')));

//   Vue.component(componentName, componentConfig.default || componentConfig);
// });

new Vue({
  router,
  i18n,
  pinia,
  vuetify,
  render: h => h(App)
}).$mount('#app');
