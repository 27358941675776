import axios from 'axios';

import i18n from '@/i18n';
import { useNotifyStore } from '@/store/notifyStore';

const { VUE_APP_BASE_URL } = process.env;

const axiosInstance = axios.create({
  timeout: 100000,
  withCredentials: true,
  baseURL: VUE_APP_BASE_URL
});

axiosInstance.defaults.headers.common['Accept'] = 'application/json';

axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('locale');

axiosInstance.interceptors.request.use(
  response => response,
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      return Promise.reject(error);
    } else {
      const notifyStore = useNotifyStore();
      const notify = {
        type: 'lime darken-2',
        message: i18n.t('labels.internetConnectionError')
      };
      notifyStore.show(notify);
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
