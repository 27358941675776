import { pinia } from '@/main';
import { useAuthStore } from '@/store/authStore';

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "authLayout" */ '@/layout/AuthLayout'),
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/LoginView.vue'),
        children: [
          {
            path: 'fogetPassword',
            name: 'login.forgetPassword',
            component: () => import(/* webpackChunkName: "itemNew" */ '@/views/auth/ForgetPasswordDialog.vue'),
            props: true
          }
        ]
      },
      {
        path: 'resetPassword/:userId&:phone',
        name: 'login.resetPassword',
        component: () => import(/* webpackChunkName: "itemNew" */ '@/views/auth/ResetPassword.vue'),
        props: true
      }
    ]
  },
  {
    path: '/app',
    component: () => import(/* webpackChunkName: "appLayout" */ '@/layout/AppLayout'),
    redirect: '/app/home',
    meta: { requiresAuth: true },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/HomeView.vue')
      },
      {
        path: 'activitiesList',
        name: 'activities.show',
        component: () => import(/* webpackChunkName: "home" */ '@/views/activities/ActivitiesList.vue')
      },
      {
        path: 'sections',
        name: 'sections',
        component: () => import(/* webpackChunkName: "sectionList" */ '@/views/section/SectionList.vue'),
        children: [
          {
            path: 'new',
            name: 'section.new',
            component: () => import(/* webpackChunkName: "sectionNew" */ '@/views/section/SectionEditor.vue')
          },
          {
            path: 'update/:sectionId',
            name: 'section.update',
            component: () => import(/* webpackChunkName: "sectionUpdate" */ '@/views/section/SectionEditor.vue'),
            props: true
          }
        ]
      },
      {
        path: 'customers',
        name: 'customers',
        component: () => import(/* webpackChunkName: "customerList" */ '@/views/customer/CustomerList.vue'),
        children: [
          {
            path: 'new',
            name: 'customer.new',
            component: () => import(/* webpackChunkName: "customerNew" */ '@/views/customer/CustomerEditor.vue')
          },
          {
            path: 'update/:customerId',
            name: 'customer.update',
            component: () => import(/* webpackChunkName: "customerUpdate" */ '@/views/customer/CustomerEditor.vue'),
            props: true
          }
        ]
      },
      {
        path: 'customers/:customerId/show',
        name: 'customer.show',
        component: () => import(/* webpackChunkName: "customerList" */ '@/views/customer/CustomerShow.vue'),
        props: true,
        children: [
          {
            path: 'new',
            name: 'representative.new',
            component: () => import(/* webpackChunkName: "representativeEditor" */ '@/views/user/UserEditor.vue')
          }
        ]
      },
      {
        path: 'users',
        name: 'users',
        component: () => import(/* webpackChunkName: "userList" */ '@/views/user/UserList.vue'),
        children: [
          {
            path: 'new',
            name: 'user.new',
            component: () => import(/* webpackChunkName: "userNew" */ '@/views/user/UserEditor.vue')
          },
          {
            path: 'update/:userId',
            name: 'user.update',
            component: () => import(/* webpackChunkName: "userUpdate" */ '@/views/user/UserEditor.vue'),
            props: true
          }
        ]
      },
      {
        path: 'changePassword',
        name: 'user.password',
        component: () => import(/* webpackChunkName: "serviceNew" */ '@/views/user/ChangePassword.vue')
      },
      {
        path: 'users/dashboard/:userId',
        name: 'user.dashboard',
        component: () => import(/* webpackChunkName: "userDashboard" */ '@/views/user/UserDashboard.vue'),
        props: true,
        children: [
          {
            path: 'profile',
            name: 'user.profile',
            component: () => import(/* webpackChunkName: "userUpdate" */ '@/views/user/ProfileEditor.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              const authStore = useAuthStore(pinia);
              if (to.params.userId === authStore.currentUserData.id) {
                next();
              } else {
                next({
                  path: '/app'
                });
              }
            }
          }
        ]
      },
      {
        path: 'services',
        name: 'services',
        component: () => import(/* webpackChunkName: "serviceList" */ '@/views/service/ServiceList.vue'),
        children: [
          {
            path: 'new',
            name: 'service.new',
            component: () => import(/* webpackChunkName: "serviceNew" */ '@/views/service/ServiceEditor.vue')
          },
          {
            path: 'update/:serviceId',
            name: 'service.update',
            component: () => import(/* webpackChunkName: "serviceUpdate" */ '@/views/service/ServiceEditor.vue'),
            props: true
          }
        ]
      },
      {
        path: 'services/:serviceId/attributes',
        name: 'attributes',
        component: () => import(/* webpackChunkName: "attributeList" */ '@/views/attribute/AttributeList.vue'),
        props: true,
        children: [
          {
            path: 'new',
            name: 'attribute.new',
            component: () => import(/* webpackChunkName: "attributeEditor" */ '@/views/attribute/AttributeEditor.vue'),
            props: true
          },
          {
            path: 'update/:attributeId',
            name: 'attribute.update',
            component: () => import(/* webpackChunkName: "attributeEditor" */ '@/views/attribute/AttributeEditor.vue'),
            props: true
          }
        ]
      },
      {
        path: 'jobs',
        name: 'jobs',
        component: () => import(/* webpackChunkName: "jobList" */ '@/views/job/JobList.vue'),
        children: [
          {
            path: 'new',
            name: 'job.new',
            component: () => import(/* webpackChunkName: "jobNew" */ '@/views/job/JobEditor.vue')
          },
          {
            path: 'update/:jobId',
            name: 'job.update',
            component: () => import(/* webpackChunkName: "jobUpdate" */ '@/views/job/JobEditor.vue'),
            props: true
          }
        ]
      },
      {
        path: 'jobs/:jobId/show',
        name: 'job.show',
        component: () => import(/* webpackChunkName: "jobShow" */ '@/views/job/JobShow.vue'),
        props: true,
        children: [
          {
            path: 'update/:jobId',
            name: 'job.show.update',
            component: () => import(/* webpackChunkName: "jobUpdate" */ '@/views/job/JobEditor.vue'),
            props: true
          },
          {
            path: 'item/new',
            name: 'item.new',
            component: () => import(/* webpackChunkName: "itemNew" */ '@/views/job/item/ItemEditor.vue'),
            props: true
          },
          {
            path: 'item/update/:itemId',
            name: 'item.update',
            component: () => import(/* webpackChunkName: "itemUpdate" */ '@/views/job/item/ItemEditor.vue'),
            props: true
          },
          {
            path: 'reject/:itemId',
            name: 'item.reject',
            component: () => import(/* webpackChunkName: "itemUpdate" */ '@/views/job/item/RejectItemForm.vue'),
            props: true
          },
          {
            path: 'moveItems/',
            name: 'item.move',
            component: () => import(/* webpackChunkName: "itemUpdate" */ '@/views/job/item/MoveItemsForm.vue'),
            props: true
          },
          {
            path: 'item/price',
            name: 'item.price',
            component: () => import(/* webpackChunkName: "itemPrice" */ '@/views/job/item/ItemPriceEditor.vue'),
            props: true
          },
          {
            path: 'execution/new',
            name: 'execution.new',
            component: () => import(/* webpackChunkName: "executionNew" */ '@/views/job/execution/ExecutionEditor.vue'),
            props: true,
            children: [
              {
                path: 'item/new',
                name: 'execution.new.item.new',
                component: () => import(/* webpackChunkName: "itemNew" */ '@/views/job/item/ItemEditor.vue'),
                props: true
              }
            ]
          },
          {
            path: 'execution/update/:executionId',
            name: 'execution.update',
            component: () => import(/* webpackChunkName: "executionUpdate" */ '@/views/job/execution/ExecutionEditor.vue'),
            props: true
          },
          {
            path: 'execution/:executionId/task/new',
            name: 'task.new',
            component: () => import(/* webpackChunkName: "taskNew" */ '@/views/job/execution/task/TaskEditor.vue'),
            props: true
          },
          {
            path: 'execution/:executionId/task/update/:taskId',
            name: 'task.update',
            component: () => import(/* webpackChunkName: "taskUpdate" */ '@/views/job/execution/task/TaskEditor.vue'),
            props: true
          }
        ]
      },
      {
        path: 'task/:taskId',
        name: 'task.details',
        component: () => import(/* webpackChunkName: "jobList" */ '@/views/task/TaskDetails.vue'),
        props: true,
        children: [
          {
            path: 'assingTask/edit/:assignedUserId?',
            name: 'assingTask.edit',
            component: () => import(/* webpackChunkName: "itemNew" */ '@/views/task/components/AssignTaskEditor.vue'),
            props: true
          },
          {
            path: 'reviewTask/',
            name: 'task.review',
            component: () => import(/* webpackChunkName: "itemNew" */ '@/views/task/components/SubmitForReviewTask.vue')
          },
          {
            path: 'rejectTask/',
            name: 'rejectTask.form',
            component: () => import(/* webpackChunkName: "itemNew" */ '@/views/task/components/RejectTaskForm.vue'),
            props: true
          },
          {
            path: 'done/:doneId/reject',
            name: 'doneReview.reject',
            component: () => import(/* webpackChunkName: "itemNew" */ '@/views/task/components/RejectDoneForm.vue'),
            props: true
          },
          {
            path: 'done/:doneId/update',
            name: 'done.update',
            component: () => import(/* webpackChunkName: "itemNew" */ '@/views/task/components/DoneEditor.vue'),
            props: true
          }
        ]
      },
      {
        path: 'invoice/:jobId',
        name: 'invoice.preview',
        component: () => import(/* webpackChunkName: "executionUpdate" */ '@/views/invoice/InvoiceView.vue'),
        props: true
      },
      {
        path: 'jobs/:jobId/show/execution/:executionId/print',
        name: 'execution.print',
        component: () => import(/* webpackChunkName: "executionPrint" */ '@/views/job/execution/ExecutionPrint.vue'),
        props: true
      },
      {
        path: 'jobs/:jobId/show/execution/:executionId/show',
        name: 'execution.show',
        component: () => import(/* webpackChunkName: "executionShow" */ '@/views/job/execution/ExecutionPage.vue'),
        props: true,
        children: [
          {
            path: 'update',
            name: 'execution.show.update',
            component: () => import(/* webpackChunkName: "executionUpdate" */ '@/views/job/execution/ExecutionEditor.vue'),
            props: true
          },
          {
            path: 'task/new',
            name: 'execution.show.task.new',
            component: () => import(/* webpackChunkName: "taskNew" */ '@/views/job/execution/task/TaskEditor.vue'),
            props: true
          },
          {
            path: 'task/update/:taskId',
            name: 'execution.show.task.update',
            component: () => import(/* webpackChunkName: "taskUpdate" */ '@/views/job/execution/task/TaskEditor.vue'),
            props: true
          }
        ]
      }
      // {
      //   path: 'jobs/:jobId/executions/:executionId/show',
      //   name: 'execution.show',
      //   component: () => import(/* webpackChunkName: "executionShow" */ '@/views/execution/ExecutionShow.vue'),
      //   props: true,
      //   children: [
      //     {
      //       path: 'price',
      //       name: 'execution.show.price',
      //       component: () => import(/* webpackChunkName: "executionPrice" */ '@/views/execution/ExecutionPriceEditor.vue'),
      //       props: true
      //     }
      //   ]
      // }
    ]
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "authLayout" */ '@/layout/AuthLayout'),
    children: [
      {
        name: '404 Error',
        path: '',
        component: () => import(/* webpackChunkName: "404-error" */ '@/views/ErrorView')
      }
    ]
  }
];

export default routes;
